/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Location } from '@reach/router'
import cx from 'classnames'
import 'what-input'

import Header from './header'
import SocialNav from './social-nav'
import './layout.css'
import PCLogo from '../images/svgs/pointless-logo-large.inline.svg'
import PCLogoWhite from '../images/svgs/pointless-logo-large--white.inline.svg'

const Layout = ({ children, backgroundColor = 'white' }) => {
  const logoStyles = { width: 282, height: 110 }

  return (
    <>
      <Location>
        {({ location }) => {
          const isHome = location.pathname === '/'

          return (
            <>
              <Header isHome={isHome} />

              <main id="content" role="main" style={{ backgroundColor }}>
                {children}
              </main>
              <footer
                id="footer"
                className={cx(
                  'flex flex-col items-center pt-40 px-20 pb-50 md:pt-50'
                )}
                style={{ backgroundColor }}
              >
                {isHome ? (
                  <PCLogo className="mb-32" style={logoStyles} />
                ) : (
                  <PCLogoWhite className="mb-32" style={logoStyles} />
                )}
                <p
                  className={cx('uppercase font-display text-12 mb-24', {
                    'text-red-900': isHome,
                    'text-white': !isHome
                  })}
                >
                  The innovation lab at{' '}
                  <a
                    className={cx({
                      'text-red-900': isHome,
                      'text-white': !isHome
                    })}
                    href="https://www.viget.com"
                  >
                    Viget
                  </a>
                </p>

                <SocialNav className="mb-24" isHome={isHome} />

                <p
                  className={cx('uppercase font-display text-12', {
                    'text-gray-200': isHome,
                    'text-white': !isHome
                  })}
                >
                  © {new Date().getFullYear()} Pointless Corp.
                </p>
              </footer>
            </>
          )
        }}
      </Location>
    </>
  )
}

export default Layout
