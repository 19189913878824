import React from 'react'
import TwitterIcon from '../images/svgs/icon-twitter.inline.svg'
import cx from 'classnames'

const SocialNav = ({ className, isHome }) => (
  <ul className={cx('list-none p-0', className)}>
    <li
      className={cx('border  rounded-full', {
        'border-gray-300': isHome,
        'border-white': !isHome
      })}
    >
      <a
        className="flex justify-center items-center w-60 h-60"
        href="http://twitter.com/pointlesscorp"
      >
        <span className="sr-only">Twitter</span>
        <TwitterIcon
          className={cx('block ', {
            'text-red-800': isHome,
            'text-white': !isHome
          })}
        />
      </a>
    </li>
  </ul>
)

export default SocialNav
