import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import Logo from '../images/svgs/logo.inline.svg'

const NavLink = ({ to, children }) => (
  <li className="ml-16 text-18">
    <a className="text-white font-display uppercase no-underline" href={to}>
      {children}
    </a>
  </li>
)

const Header = ({ isHome }) => (
  <header
    id="header"
    className={cx('absolute w-full top-0 py-32 z-50', {
      'sm-d:bg-gray-400': isHome
    })}
  >
    <div className="max-w-1220 mx-auto px-24 lg:px-12 flex justify-center md:justify-between items-center">
      <h1>
        <Link className="block" to="/">
          <Logo className="block" style={{ height: 34, width: 172 }} />
          <span className="sr-only">Pointless Corp.</span>
        </Link>
      </h1>

      <nav>
        <ul className="list-none p-0 hidden md:flex">
          <NavLink to="/#about">About</NavLink>
          <NavLink to="/#projects">Projects</NavLink>
          <NavLink to="/#process">Process</NavLink>
          <NavLink to="/#faq">FAQ</NavLink>
          <NavLink to="/#contact">Contact</NavLink>
        </ul>
      </nav>
    </div>
  </header>
)

export default Header
